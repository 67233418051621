/* You can add global styles to this file, and also import other style files */
@import "../node_modules/materialize-css/sass/materialize";
.smaller{
  font-size:14px;
}
.smallest{
  font-size:12px;
}
.large{
  font-size:120%;
}
.larger{
  font-size:150%;
}
.largexl{
  font-size:200%;
}


.smallcaps{
  font-variant:small-caps;
}
.bolder{
  font-weight: bolder;

}
.buttonSpace{
  margin:5px;
}
.underline{
  text-decoration: underline;
}

.backgroundprimary{
  background-color: $primary-color;
}
.textprimary{
  color: $primary-color;
}
.textsecondary{
  color: $secondary-color;
}

@media #{$small-and-down} {
  // styles for small screens and down
  .largexxl{
    font-size: 200%;
    font-weight: bolder;
  }
  .welcomelogo{
    width:80%;
  }
}
@media #{$medium-and-up} {
  .largexxl{
    font-size: 300%;
    font-weight: bolder;
  }
  .welcomelogo{
    width:40%;
  }
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}
main {
  flex: 1 0 auto;
}

// div:not(.col):not(.row):not(.container){
//   border: 1px solid seagreen;
// }
// div.col{
//   border: 1px solid orange;
// }
// div.container{
//   border: 3px solid purple;
// }
// div.row{
//   border: 2px solid red;
// }
// i{
//   border: 1px solid blue;
// }
// p{
//   border: 1px solid springgreen;
// }
// img{
//   border: 1px solid orchid;
// }
